import React from 'react';
import emailjs from 'emailjs-com';
import './contact_style.scss';


const ContactMe = () => {


    const SendEmail = (e) => {
        e.preventDefault();


        emailjs
            .sendForm('service_i8p933h', 'template_15f3ggt', e.target, 'XtFaKb_zt6d3dAKgB')
            .then((result) => {
                console.log(result.text);
                e.target.reset();
                e.target.classList.add("success");
                e.target.classList.remove("error");

            }, (error) => {
                console.log(error.text);
                e.target.classList.add("error");
                e.target.classList.remove("success");
            });
    };

    return (
        <div className='contact-container'>
            <div className="contact-content">
                <div className="contact-underline" ><h1 className="Title">Contact Me</h1></div>
                <p>Hi! I'd love the opportunity to speak with you!</p>
                <svg className="contact-image" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 703.81722 573.37046">
                    <path id="e77d6775-0d6e-4379-8bfc-a6b010ad5ee5-762" data-name="Path 461" d="M454.38905,703.63625a21.57359,21.57359,0,0,0,20.50932,5.17853c9.16409-2.60129,15.38339-10.86908,20.90329-18.633l16.33356-22.95923-13.76528,2.52422c-9.89941,1.81469-20.04352,3.72038-28.66453,8.91439s-15.526,14.46608-14.98,24.5152" transform="translate(-248.09139 -163.31477)" fill="#34d17d"/>
                    <path id="a925376f-81ed-452b-bc21-27b5ed1cb659-763" data-name="Path 462" d="M441.59444,734.5645c2.414-8.23128,4.842-16.57491,9.10417-24.07695,3.7855-6.662,8.95768-12.50745,15.78147-16.1419a35.24564,35.24564,0,0,1,11.605-3.77527c1.05845-.14935,1.23086,1.49784.1775,1.64745a33.507,33.507,0,0,0-18.638,9.16558c-5.63682,5.51525-9.33425,12.62565-12.06784,19.94372-1.65522,4.42483-2.99064,8.95782-4.3197,13.49049a.85428.85428,0,0,1-.94553.69314.83341.83341,0,0,1-.69316-.94555Z" transform="translate(-248.09139 -163.31477)" fill="#f2f2f2"/>
                    <path d="M707.40533,403.05265a11.03878,11.03878,0,0,1,13.60532,10.0701l37.76434,10.616-15.409,13.34575-33.49929-12.12721a11.09861,11.09861,0,0,1-2.46133-21.90467Z" transform="translate(-248.09139 -163.31477)" fill="#ffb7b7"/>
                    <polygon points="556.357 561.294 545.349 561.293 540.112 518.835 556.359 518.836 556.357 561.294" fill="#ffb7b7"/>
                    <path d="M807.25547,735.279l-35.49353-.00132v-.44893A13.81582,13.81582,0,0,1,785.577,721.01388h.00088l21.67824.00087Z" transform="translate(-248.09139 -163.31477)" fill="#2f2e41"/>
                    <polygon points="620.859 561.294 609.851 561.293 604.614 518.835 620.86 518.836 620.859 561.294" fill="#ffb7b7"/>
                    <path d="M871.75722,735.279l-35.49353-.00132v-.44893a13.81582,13.81582,0,0,1,13.81507-13.81485h.00088l21.67824.00087Z" transform="translate(-248.09139 -163.31477)" fill="#2f2e41"/>
                    <polygon points="547.363 317.603 531.148 429.917 535.188 548.251 559.537 550.19 567.151 444.067 586.533 384.893 597.928 469.245 598.396 548.251 623.539 550.19 632.308 384.09 625.858 311.854 547.363 317.603" fill="#2f2e41"/>
                    <circle cx="594.26143" cy="141.57771" r="25.65142" fill="#ffb7b7"/>
                    <path d="M826.54186,344.49643,798.22329,487.16178s47.67807,13.68408,68.565,1.05146,11.52743-51.63056,11.52743-51.63056l-3.82537-66.35791-7.069-16.78062-5.99788-13.85207Z" transform="translate(-248.09139 -163.31477)" fill="#cbcbcb"/>
                    <path d="M818.25272,445.76409l9.78259-104.03731-7.82346,5.71168L792.858,356.32218l-1.872,73.24592L771.85133,525.888s13.67716,26.688,30.62732-17.46438Z" transform="translate(-248.09139 -163.31477)" fill="#2f2e41"/>
                    <polygon points="557.931 203.962 544.767 193.007 515.18 257.279 482.164 250.047 480.604 269.546 528.44 288.477 561.038 243.095 557.931 203.962" fill="#2f2e41"/>
                    <polygon points="609.144 259.677 612.831 175.005 626.399 185.512 650.63 192.266 647.077 259.677 652.19 364.277 626.786 372.323 619.655 306.32 609.144 259.677" fill="#2f2e41"/>
                    <path d="M858.86576,491.15162a11.03874,11.03874,0,0,1,14.68847-8.41192l24.8965-30.31518,6.02883,19.47309L879.91313,497.7a11.09861,11.09861,0,0,1-21.04737-6.54836Z" transform="translate(-248.09139 -163.31477)" fill="#ffb7b7"/>
                    <polygon points="634.407 201.665 650.63 192.266 691.451 272.878 651.652 328.143 628.952 309.993 656.516 274.048 631.394 235.44 634.407 201.665" fill="#2f2e41"/>
                    <path d="M866.47836,284.38547l1.24356-2.50292-6.25732-3.10891s-6.90282-11.23164-19.39374-8.0753-18.11076,5.04533-18.11076,5.04533l-6.2415,3.14051,3.13258,3.11681-5.61972,1.88907,3.75437,1.86533-4.36831,2.51082,2.35155,12.87131s3.90577-9.76239,11.4145-6.03172,21.24335-1.92852,21.24335-1.92852l11.93234,23.09286s2.46164-8.0952,6.85062-5.93665C868.40994,310.33353,879.62262,292.47654,866.47836,284.38547Z" transform="translate(-248.09139 -163.31477)" fill="#2f2e41"/>
                    <path d="M710.6639,322.01909h-.164l-94.07127,40.16883-128.70885,54.9597a4.15132,4.15132,0,0,1-3.17971.01638L351.75039,362.07316l-96.38231-39.98853-.14722-.06554h-.16408a5.74247,5.74247,0,0,0-5.736,5.73607V576.86409a5.74247,5.74247,0,0,0,5.736,5.73606H710.6639a5.74247,5.74247,0,0,0,5.736-5.73606V327.75516A5.74247,5.74247,0,0,0,710.6639,322.01909Z" transform="translate(-248.09139 -163.31477)" fill="#fff"/>
                    <path d="M711.07286,323.658a.81682.81682,0,0,1-.46775-.14686L483.98245,165.68461a4.10983,4.10983,0,0,0-4.69419.008L254.29805,323.50952a.8196.8196,0,0,1-.94128-1.342L478.34722,164.35067a5.75429,5.75429,0,0,1,6.57193-.0108L711.54182,322.16594a.81961.81961,0,0,1-.469,1.492Z" transform="translate(-248.09139 -163.31477)" fill="#3f3d56"/>
                    <polygon points="19.063 165.938 233.765 6.817 450.097 177.26 244.827 298.946 133.384 273.544 19.063 165.938" fill="#e6e6e6"/>
                    <path d="M390.88289,537.42153H284.81463a4.95544,4.95544,0,1,1-.01461-9.91086H390.88289a4.95544,4.95544,0,1,1,.01462,9.91086Z" transform="translate(-248.09139 -163.31477)" fill="#34d17d"/>
                    <path d="M322.86948,516.11616H284.81455a4.95544,4.95544,0,1,1-.01461-9.91086h38.06954a4.95544,4.95544,0,0,1,.0147,9.91086h-.0147Z" transform="translate(-248.09139 -163.31477)" fill="#34d17d"/>
                    <path d="M484.8761,417.88073a6.19853,6.19853,0,0,1-2.35111-.46056L350.1115,362.47786V190.49925a5.74278,5.74278,0,0,1,5.736-5.73606H609.87316a5.74277,5.74277,0,0,1,5.736,5.73606V362.59789l-.24889.10645-128.06192,54.689A6.24086,6.24086,0,0,1,484.8761,417.88073Z" transform="translate(-248.09139 -163.31477)" fill="#34d17d"/>
                    <path d="M709.43462,322.01909h-.164l-94.07136,40.16879-128.70885,54.9597a4.15132,4.15132,0,0,1-3.17971.01638l-132.7896-55.0908-96.38231-39.98853-.14722-.06554h-.16416a5.74246,5.74246,0,0,0-5.736,5.73607V576.86409a5.74245,5.74245,0,0,0,5.736,5.73606H709.43462a5.74245,5.74245,0,0,0,5.736-5.73606V327.75516A5.74246,5.74246,0,0,0,709.43462,322.01909Zm4.09721,254.845a4.0988,4.0988,0,0,1-4.09721,4.09717H253.82741a4.0988,4.0988,0,0,1-4.09721-4.09717V327.75516a4.10624,4.10624,0,0,1,3.94156-4.09718l96.84934,40.18518,132.15906,54.83677a5.835,5.835,0,0,0,4.45766-.02457l128.06144-54.68924L709.59862,323.658a4.11048,4.11048,0,0,1,3.93313,4.09718Z" transform="translate(-248.09139 -163.31477)" fill="#3f3d56"/>
                    <path d="M464.26159,249.04935H374.12345a6.55551,6.55551,0,0,1,0-13.111h90.13814a6.55551,6.55551,0,0,1,0,13.111Z" transform="translate(-248.09139 -163.31477)" fill="#fff"/>
                    <path d="M417.55363,221.18847H374.12345a6.5555,6.5555,0,0,1,0-13.111h43.43018a6.5555,6.5555,0,0,1,0,13.111Z" transform="translate(-248.09139 -163.31477)" fill="#fff"/>
                    <path d="M550.27673,314.60434H412.61119a6.5555,6.5555,0,0,1,0-13.111H550.27673a6.5555,6.5555,0,0,1,0,13.111Z" transform="translate(-248.09139 -163.31477)" fill="#fff"/>
                    <path d="M550.27673,344.92351H412.61119a6.55551,6.55551,0,1,1,0-13.111H550.27673a6.55551,6.55551,0,1,1,0,13.111Z" transform="translate(-248.09139 -163.31477)" fill="#fff"/>
                    <path d="M950.90861,736.31477h-263a1,1,0,0,1,0-2h263a1,1,0,0,1,0,2Z" transform="translate(-248.09139 -163.31477)" fill="#cbcbcb"/>
                    <path d="M491.90861,736.31477h-97a1,1,0,1,1,0-2h97a1,1,0,0,1,0,2Z" transform="translate(-248.09139 -163.31477)" fill="#cbcbcb"/>
                    <path id="e8398aff-523f-434a-af20-c024070a1d12-764" data-name="Path 461" d="M430.5752,705.39672a21.57362,21.57362,0,0,0,18.19364-10.79079c4.66332-8.30666,3.24284-18.55451,1.68179-27.95186l-4.60906-27.7969-7.9801,11.49663c-5.73938,8.26745-11.58811,16.772-14.03806,26.534s-.80744,21.20544,6.666,27.94567" transform="translate(-248.09139 -163.31477)" fill="#34d17d"/>
                    <path id="e2bc4c04-3c78-4f76-9d7c-3717db50fbca-765" data-name="Path 462" d="M443.313,736.3484c-4.09285-7.53856-8.255-15.16664-10.52316-23.49146-2.01381-7.39306-2.46855-15.18494-.19313-22.57385a35.24556,35.24556,0,0,1,5.56614-10.86028c.64517-.85229,1.929.194,1.28759,1.04293a33.507,33.507,0,0,0-6.75177,19.64175c-.10752,7.88543,2.2849,15.53429,5.50717,22.65072,1.94665,4.30459,4.19632,8.46035,6.45027,12.61139a.85428.85428,0,0,1-.18164,1.15823.8334.8334,0,0,1-1.15825-.18164Z" transform="translate(-248.09139 -163.31477)" fill="#f2f2f2"/>
                </svg>
            </div>
            <form className='contact-form' onSubmit={SendEmail}>
                <input placeholder='Name' type="text" className="name" name="name" required/>
                <input placeholder='Email' type="Email" className="email" name="email" required/>
                <textarea placeholder='Message' type="text" className="message" name="message" required/>
                <span id='successBox' className='successBox'>Thanks! I'll be in touch soon.</span>
                <span id='errorBox' className='errorBox'>Sorry, there is something wrong. Please try again soon or email me directly at zkrevis@gmail.com.</span>
                <button type="submit">Submit</button>
            </form>
        </div>
    )
}

export default ContactMe