import React, { useState } from 'react';
import './projects_style.scss';
import {GoLinkExternal} from 'react-icons/go'

import {BsGithub} from 'react-icons/bs';



const Projects = () => {
    return (
        <>
            <div className="projects-container">
                <div className="projects-underline">
                    <h1 className="projects-title">Projects</h1>
                </div>

                <ProjectArea name='To-Do List' languages={["React", "TypeScript", "GoLang", "MongoDB"]} github="github.com/zachkrevis/To-Do-List" dates="January 2023">
                    <p>
                    The web app I created is designed to help users learn GoLang, MongoDB, and TypeScript by building a practical application. The app allows users to push reminders to a MongoDB database from a React web application. Users can create reminders with a title, due date, and completion status, and the app will store them in the database. The app also allows users to mark their reminders as complete with one simple button. 
                    </p>
                </ProjectArea>

                <ProjectArea name='Substance Abuse iOS App' languages={["Swift", "SwiftUI", "Core Data"]} github="github.com/zachkrevis/iOS-Substance-Abuse-App" dates="November 2022-December 2022">
                    <p>
                    The substance abuse app is a user-friendly solution designed with Swift, SwiftUI, and Core Data to help users track their sobriety progress and financial savings by avoiding substance-specific vices. The app enables users to calculate the amount of money saved, store notes and tips you want to remember, and keep track of the length of time they have been sober. Using Core Data, users can store and track their progress over time, making it easier to stay motivated to continue on their path to recovery.
                    </p>
                </ProjectArea>

                <ProjectArea name='Memory Manager' languages={["C"]} github="github.com/zachkrevis/Memory-Management-System" dates="November 2022-December 2022">
                    <p>
                    The memory management application is a solution written in C that employs the FIFO and third chance replacement policies. The app allows users to simulate the allocation and deallocation of memory blocks and evaluate how the different policies affect the system's performance. The FIFO policy allocates memory blocks in the order in which they were requested, while the third chance policy gives priority to blocks that have not been recently accessed.    
                    </p>
                </ProjectArea>

                <ProjectArea name='Spellster iOS App' languages={["Swift", "SwiftUI"]} github="github.com/zachkrevis/Spellster-IOS-App" dates="October 2022">
                    <p>
                    The app is a word game that challenges users to form as many words as possible from a set of letters, just like the New York Times Spelling Bee game. 
                     The user can generate a new string of letters to choose from at any time, resetting their score to zero. The game provides a point total that changes based on the length of the word. Additionally, users can use a hint feature to see how many words and points are possible to make from the set of letters. 
                    </p>
                </ProjectArea>

                <ProjectArea name='Tic-Tac-Toe Game' languages={["React", "JavaScript", "Socket.io", "Node.js"]} github="github.com/zachkrevis/TicTacToe/" dates="May 2022">
                    <p>
                        I made a full-stack project that allows two random people to face off in a game of tic-tac-toe.
                        I made the back-end using socket.io which allows the server to send a receive signals to and from the client.
                        I used these signals to set useStates on the front-end and allow players to see a fully functioning tic-tac-toe game.
                    </p>
                </ProjectArea>


            </div>
        </>
    )
};

const ProjectArea = (props) => {

    const [projectsClass, setProjectsClass] = useState("projects-project")

    const clickHandler = (e) => {
        if (projectsClass.includes("active")) {
            setProjectsClass("projects-project")
        } else {
            setProjectsClass("projects-project active")
        }
    }

    return (
        <>
            <div onClick={clickHandler} className={projectsClass}>
                <div className="projects-dropdown">
                    <div className="project-title" >
                        <h2>{props.name}</h2>
                        {props.dates && <p>{props.dates}</p>}
                    </div>
                    <div className='projects-arrow' />
                </div>
                <div className="projects-content">
                    {props.languages !== undefined && <div className="projects-languages">
                        {props.languages.map((i) => <p key={i} className='projects-language-node'>{i}</p>)}
                    </div>
                    }
                    {props.children}
                    <span onClick={ (e) => {e.stopPropagation()}}>

                        {props.website !== undefined &&
                            <a href={"https://" + props.website} target="_blank" rel="noreferrer"> <GoLinkExternal className='link-icon' />{props.website}</a>
                        }

                        {props.github !== undefined && 
                            <a href={"https://" + props.github} target="_blank" rel="noreferrer"> <BsGithub className='link-icon'/>{props.github}</a>
                        }

                    </span>
                </div>
            </div>
        </>
    )
}

export default Projects;
