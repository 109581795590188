import React from 'react'
import 'aos/dist/aos.css';
import './skills_style.scss';
import {FaJava, FaPython, FaCode, FaReact, FaSass, FaNodeJs, FaDatabase, FaLaptopCode, FaLightbulb, FaCloud, FaSwift } from 'react-icons/fa';
import {MdWeb} from 'react-icons/md';
import {SiTypescript, SiJavascript} from 'react-icons/si'
import {IoHardwareChip} from 'react-icons/io5'
import {TiFlowMerge} from 'react-icons/ti'
import {BsJoystick} from 'react-icons/bs'


const Skills = () => {

    return (
        <div className='skills-containter'>
            <div className='skills-block'>
                <div className='skills-underline'>
                    <h1>Skills</h1>
                </div>
                <ul className="skills-list" >
                    <li><FaJava style={{ fill: "#34d17d" }} className='skills-icon'/> <span>Java</span></li>
                    <li><FaPython style={{ fill: "#34d17d" }} className='skills-icon'/> <span>Python</span></li>
                    <li><FaCode style={{ fill: "#34d17d" }} className='skills-icon'/> <span>C/C++</span></li>
                    <li><FaSwift style={{ fill: "#34d17d" }} className='skills-icon'/> <span>Swift</span></li>
                    <li><SiTypescript style={{ fill: "#34d17d" }} className='skills-icon'/> <span>TypeScript</span></li>
                    <li><SiJavascript style={{ fill: "#34d17d" }} className='skills-icon'/> <span>JavaScript</span></li>
                    <li><FaReact style={{ fill: "#34d17d" }} className='skills-icon'/> <span>React</span></li>
                    <li><FaSass style={{ fill: "#34d17d" }} className='skills-icon'/> <span>Sass</span></li>
                    <li><FaNodeJs style={{ fill: "#34d17d" }} className='skills-icon'/> <span>NodeJS</span></li>
                </ul>
            </div>
            <div className='skills-block'>
                <div className='skills-underline'>
                    <h1>Relavent Coursework</h1>
                </div>
                <ul className="skills-list">
                    <li><TiFlowMerge style={{ fill: "#34d17d" }} className='skills-icon'/> Data Structures and Algorithms</li>
                    <li><FaLaptopCode style={{ fill: "#34d17d" }} className='skills-icon'/> Intermediate Programming</li>
                    <li><IoHardwareChip style={{ fill: "#34d17d" }} className='skills-icon'/> Computer Organization and Design</li>
                    <li><FaDatabase style={{ fill: "#34d17d" }} className='skills-icon'/> Database Management Systems</li>
                    <li><MdWeb style={{ fill: "#34d17d" }} className='skills-icon'/> Web Development and Design</li>
                    <li><FaCloud style={{ fill: "#34d17d" }} className='skills-icon'/> OOP with Web-Based Application</li>
                    <li><FaLightbulb style={{ fill: "#34d17d" }} className='skills-icon'/> Digital Design: Theory and Practice</li>
                    <li><BsJoystick style={{ fill: "#34d17d" }} className='skills-icon'/> Intro to Building Computer Games</li>
                </ul>
            </div>
        </div>
    )
}

export default Skills
