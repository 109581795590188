import React from 'react';
import './hero_style.scss';

import { Link } from 'react-scroll';

const HeroSection = () => {

    return (
        <div className='hero-container'>
            
            <div className='hero-content'>
                <h1>Zach Krevis</h1>
                <p>SOFTWARE ENGINEER, FULL-STACK DEVELOPER AND iOS DEVELOPER</p>
                <Link className="hero-link" to="contact-container" spy={true} smooth={true} duration={500} offset={-52} activeClass="active">Contact Me</Link>
            </div>
        </div>
    );
};

export default HeroSection;
