import React from 'react';
import './SidebarElements.scss';
import { Link } from 'react-scroll';

const Sidebar = ({isOpen, toggle}) => {

  return (
    <aside className={`sidebar-container ${(isOpen) ? "open" : "closed" } `}>
      <div className="sidebar-wrapper">
        <div className="sidebar-items">
          <Link className="sidebar-link" to="about-container" spy={true} smooth={true} duration={500} onClick={toggle}>
            About Me
          </Link>

          <Link className="sidebar-link" to="skills-containter" spy={true} smooth={true} duration={500} onClick={toggle}>
            Skills
          </Link>

          <Link className="sidebar-link" to="projects-container" spy={true} smooth={true} duration={500} onClick={toggle}>
            Projects
          </Link>

          <Link className="sidebar-link" to="contact-container" spy={true} smooth={true} duration={500} onClick={toggle}>
            Contact
          </Link>

        </div>
      </div>
    </aside>
  )
};

export default Sidebar;
