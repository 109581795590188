import React from 'react';
import './App.scss';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import Home from './pages/home/';
import Error404 from './pages/404/';


const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <Error404 />
  },
]);



function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
