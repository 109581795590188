import React from 'react';
import './navbar_style.scss';

import { Link } from 'react-scroll';



const Navbar = ({isOpen, toggle}) => {

    return (
        <>
            <nav>
                <div className='navbar-container'>
                    <div className='navbar-mobile-button ' onClick={toggle}>
                        <div className="hamburger-menu">
                            <svg className={`hamburger-btn ${isOpen && 'active'}`} height='48' width='48' viewBox='0 0 100 100'>
                                <path className="hamburger-middle" d="M 20 50 h 60"  fill="None" stroke="#ffffff" strokeWidth="10"/>
                                <path className="hamburger-curves" d="M 20 28 h 60 C 106 28 103 110 80 84 L 20 16 M 20 72 h 60 C 106 72 103 -10 80 16 L 20 84" fill="None" stroke="#ffffff" strokeWidth="10"></path>
                            </svg>
                        </div>
                    </div>
                    <ul className='navbar-menu'>
                        <li className='navbar-item'>
                            <Link className="navbar-link" to="about-container" spy={true} smooth={true} duration={500} activeClass="active">About Me</Link>
                        </li>
                        <li className='navbar-item'>
                            <Link className="navbar-link" to="skills-containter" spy={true} smooth={true} duration={500} activeClass="active">Skills</Link>
                        </li>
                        <li className='navbar-item'>
                            <Link className="navbar-link" to="projects-container" spy={true} smooth={true} duration={500} activeClass="active">Projects</Link>
                        </li>
                        <li className='navbar-item'>
                            <Link className="navbar-link" to="contact-container" spy={true} smooth={true} duration={500} activeClass="active">Lets Talk</Link>
                        </li>
                    </ul>
                </div>
            </nav>
        </>
    )
};

export default Navbar;
