import React from "react";
import "./about_style.scss";
import Photo from "../../Photos/face.png";

const AboutMe = () => {
  return (
    <div className="about-container">
      <div className="about-content">
        <div className="about-section">
          <img className="about-art" src={Photo} alt="" />
        </div>
        <div className="about-section">
          <div className="about-text">
            <div className="about-underline">
              <h1>Who am I?</h1>
            </div>
            <p>
              Hi there! My name is <strong>Zachary Krevis</strong> and I am a
              software engineer. I recently graduated from Pennsylvania State
              University with a degree in <em>Computer Science</em>. From a
              young age, I developed a passion for computers and software
              engineering. In fact, my interest in the field was sparked way back when I
              had to share the family computer with my sister.
            </p>
            <p>
              Throughout my academic journey, I have acquired a diverse set of
              technical skills that have equipped me with the ability to design
              and develop software solutions that are tailored to meet specific
              user needs. My academic background has provided me with the
              technical foundation that I need to succeed in my field, but my
              passion and drive to create innovative solutions that solve
              complex problems are what truly sets me apart.
            </p>
            <p>
              When I'm not coding, you can usually find me playing basketball, talking to friends,
              or just enjoying time with my girlfriend and dog. These hobbies allow me to clear my
              head and focus on the things that truly matter to me - living life
              to the fullest and making a positive impact on the world through
              technology.
            </p>
            <p>
              I am excited about what the future holds and look forward to
              creating innovative solutions that make a difference in people's
              lives.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
