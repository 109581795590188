import React from 'react';
import './404_style.scss';

const Error404 = () => {
    return (
        <h1 className="body404">Error 404</h1>
    )

}

export default Error404;